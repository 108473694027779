import React from 'react';
import { NextSeo } from 'next-seo';
import { makeStyles } from '@material-ui/core/styles';

import theme from '../src/theme';
import { getPageData } from '../src/utils/getPageData';
import componentMapping from '../src/utils/componentMapping';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

export default function HomePage (props) {
    const classes = useStyles(theme);

    const pageComponents = props.content
        .map(c => ({
            component: componentMapping(c.contentType),
            ...c,
        }))
        .filter(n => n.component);

    return (
        <div className={classes.root}>
            <NextSeo
                title={props.seoTitle}
                description={props.seoDescription}
                canonical="https://www.thecodecosmetics.com"
            />
            {pageComponents.map((c, i) => (
                React.createElement(c.component, {
                    key: `${c.id}+${i}`,
                    ...c,
                })
            ))}
        </div>
    );
}

const fetchPageData = async (locale) => {
    const data = await getPageData('/', locale);
    const pageProps = { ...data, };
    pageProps.id = data.id;
    return pageProps;
};

export async function getStaticProps (context) {
    const { preview, params, locale, } = context;
    const pageProps = await fetchPageData(locale);

    if (preview && params) {
        console.info('Fetch preview data for ', params);
    }

    return { props: pageProps, };
}
